import _get from "lodash.get";

import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const getProductName = code => {
  switch (code) {
    case "laroca":
      return "Разходка до La Roca Beach Bar";
    case "fidonisi":
      return "Разходка покрай остров Фидониси";
    case "vrasidas":
      return "Разходка до Плаж Врасида / Vrasidas beach";
    case "elies":
      return "Разходка до Плаж Елис / Elies Beach";
    default:
      return null;
  }
};

export const getProductImage = code => {
  const images = {
    laroca: (
      <StaticImage
        src="../images/laroca.jpg"
        alt={getProductName(code)}
        loading="eager"
        placeholder="blurred"
        layout="constrained"
        width={406}
        style={{ height: "100%" }}
        formats={["AUTO", "WEBP", "AVIF"]}
        // transformOptions={{ fit: "fill" }}
      />
    ),
    fidonisi: (
      <StaticImage
        src="../images/fidonisi.jpg"
        alt={getProductName(code)}
        loading="eager"
        placeholder="blurred"
        layout="constrained"
        width={406}
        style={{ height: "100%" }}
        formats={["AUTO", "WEBP", "AVIF"]}
        // transformOptions={{ fit: "fill" }}
      />
    ),
    vrasidas: (
      <StaticImage
        src="../images/vrasidas.jpg"
        alt={getProductName(code)}
        loading="eager"
        placeholder="blurred"
        layout="constrained"
        width={406}
        style={{ height: "100%" }}
        formats={["AUTO", "WEBP", "AVIF"]}
        // transformOptions={{ fit: "fill" }}
      />
    ),
    elies: (
      <StaticImage
        src="../images/elies.jpg"
        alt={getProductName(code)}
        loading="eager"
        placeholder="blurred"
        layout="constrained"
        width={406}
        style={{ height: "100%" }}
        formats={["AUTO", "WEBP", "AVIF"]}
        // transformOptions={{ fit: "fill" }}
      />
    )
  };

  return images[code];
};

export const getProductMapImage = code => {
  const images = {
    laroca: (
      <StaticImage
        src="../images/laroca_map.png"
        loading="eager"
        alt={`${getProductName(code)} - карта`}
        placeholder="blurred"
        layout="constrained"
        width={203}
        style={{ height: "100%" }}
        formats={["AUTO", "WEBP", "AVIF"]}
        // transformOptions={{ fit: "fill" }}
      />
    ),
    fidonisi: (
      <StaticImage
        src="../images/fidonisi_map.png"
        loading="eager"
        alt={`${getProductName(code)} - карта`}
        placeholder="blurred"
        layout="constrained"
        width={203}
        style={{ height: "100%" }}
        formats={["AUTO", "WEBP", "AVIF"]}
        // transformOptions={{ fit: "fill" }}
      />
    ),
    vrasidas: (
      <StaticImage
        src="../images/vrasidas_map.png"
        loading="eager"
        alt={`${getProductName(code)} - карта`}
        placeholder="blurred"
        layout="constrained"
        width={203}
        style={{ height: "100%" }}
        formats={["AUTO", "WEBP", "AVIF"]}
        // transformOptions={{ fit: "fill" }}
      />
    ),
    elies: (
      <StaticImage
        src="../images/elies_map.png"
        loading="eager"
        alt={`${getProductName(code)} - карта`}
        placeholder="blurred"
        layout="constrained"
        width={203}
        style={{ height: "100%" }}
        formats={["AUTO", "WEBP", "AVIF"]}
        // transformOptions={{ fit: "fill" }}
      />
    )
  };

  return images[code];
};

export const round = function (value) {
  return Number(Math.round(value + "e2") + "e-2");
};

export const requestError = err => {
  let errorMessage = "";
  if (_get(err, "response.data")) {
    const data = _get(err, "response.data");
    if (_get(data, "Message")) {
      errorMessage = _get(data, "Message");
    } else if (_get(data, "message")) {
      errorMessage = _get(data, "message");
    } else {
      errorMessage = data;
    }
  } else if (_get(err, "response")) {
    errorMessage = JSON.stringify(err.response);
  } else if (err.request) {
    errorMessage = "The request was made but no response was received";
  } else {
    errorMessage = "Something happened in setting up the request that triggered an Error";
  }
  return errorMessage;
};

export const passengersText = number => {
  switch (number) {
    case 1:
      return "един";
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return `${number}-ма`;
    default:
      break;
  }
};

export const timeout = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const mapHtmlEntityIcons = icon => {
  switch (icon) {
    case "beach":
      return "&#x1F3D6;";
    case "boat":
      return "&#x1F6E5;";
    default:
      return "";
  }
};
